const dev = {
    apiUrl: 'https://app-dev.visionxr.in/api',
  };
  
  const prod = {
    apiUrl: 'https://app-dev.visionxr.in/api',
  };
  
  const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;
  
  export default config;