import './App.css';
import './Css/Nav.css';
import './Css/SignUp.css';
import Nav from './Components/Nav';
import Login from './Components/Login';
import Upload from './Components/Upload';
import ModelView from './Components/ModelView';
import Build from './Components/Build';
import List from './Components/List';
import Home from './Components/Home';
import SignUp from './Components/SignUp';
import Profile from './Components/Profile';
import PrivateRoute from './Components/PrivateRoute';
import OtpVerification from './Components/OtpVerification';
import ResetPassword from './Components/ResetPassword';
import { BrowserRouter, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Header from './Components/Header';
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';


function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Invalid token", error);
    return true;
  }
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/myfiles");
      }
    }
  }, [navigate, location.pathname]);

  if(isLoggedIn) {
    return (
      <div>
        <Nav />
        <Header setIsLoggedIn={setIsLoggedIn}/>
        <div className='App'>
          <Routes>
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/myfiles" element={<PrivateRoute><Upload setIsLoggedIn={setIsLoggedIn}/></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/ModelView" element={<ModelView />} />
            <Route path="/Logout" element={<h1>Logout Component</h1>} />
            <Route path="/Build" element={<Build />} />
            <Route path="/List" element={<List />} />
          </Routes>
        </div>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/otp-verification' element={<OtpVerification />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect all other routes to login when not logged in */}
      </Routes>
    );
  }
}


function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default WrappedApp;
