import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!email || !password) {
      setMessage('All fields are required.');
      setIsSuccess(false);
      return;
    }

    setLoading(true);
    const userData = { email, password };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        const jsonData = await response.json();

        if (response.ok) {
          const { token } = jsonData;
          setMessage('Login successful!');
          setIsSuccess(true);

          localStorage.setItem('token', token);

          setTimeout(() => navigate('/myfiles'), 500);
        } else {
          setMessage(`Login failed: ${jsonData.error || 'Invalid credentials'}`);
          setIsSuccess(false);
        }
      } else {
        setMessage(`Login failed: Unexpected response.`);
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage(`Login failed: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="component">
      <h1 className='signup-text'>Login</h1>
      {message && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>{message}</p>
      )}
      <form onSubmit={handleSubmit}>
        <input
          className="inputBox"
          type="email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <input
          className="inputBox"
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <button
          className="appButton"
          type="submit"
          disabled={loading || !email || !password}
        >
          {loading ? 'Logging in...' : 'Log In'}
        </button>
      </form>
      <p className="signup-text">
        <span 
          onClick={() => navigate('/reset-password')} 
          className="signup-link"
          style={{ cursor: 'pointer' }}
        >
          Forgot Password?
        </span>
      </p>
      <p className="signup-text">
        Don't have an account?{' '}
        <span onClick={() => navigate('/signup')} className="signup-link">
          Sign Up
        </span>
      </p>
    </div>
  );
};

export default Login;
