import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Css/Nav.css';
import { FaHome, FaUser } from 'react-icons/fa';
import { FiFile } from 'react-icons/fi';

const Nav = () => {

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="dashboardNav">
      <ul className="dashboard-menu">
        <li>
          <Link 
            to="/myfiles" 
            className={`link ${currentPath  === '/myfiles' ? 'active' : ''}`} 
          >
            <FiFile style={{ fontSize: '1.1rem', marginRight: '15px' }} />
            My files
          </Link>
        </li>
        <li>
          <Link 
            to="/Profile" 
            className={`link ${currentPath  === '/Profile' ? 'active' : ''}`}
          >
            <FaUser style={{ fontSize: '1.1rem', marginRight: '15px' }} />
            Profile
          </Link>
        </li>
        {/* <li>
          <Link
            to="home"
            className={`link ${currentPath  === '/home' ? 'active' : ''}`}
          >
            <FaHome style={{ fontSize: '1.1rem', marginRight: '15px' }} />
            Home
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Nav;

