import React, { useState, useRef, useEffect } from 'react';
import '../Css/Header.css';
import config from '../config';
import { FaEye, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Header = ({ activateHomeLink, setIsLoggedIn }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({ firstname: '', lastname: '', email: '' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logout = (e) => {
    e.preventDefault(); 
    localStorage.removeItem('token'); 
    setIsLoggedIn(false);
    navigate('/'); 
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setUserDetails({
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            email: data.user.email,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, isMenuOpen]);

  return (
    <div className="header">
      <h1>
        <Link 
          to="/home" 
          onClick={activateHomeLink} 
          style={{ textDecoration: 'none' }} 
          className={`header-link ${currentPath === '/home' ? 'active' : ''}`}
        >
          <FaEye size={30} className="logo" />
          Vision<span className="xr">xr</span>
        </Link>
      </h1>
      
      <div className="profile-section">
        <div className="profile-icon" onClick={toggleMenu}>
          <img src="./bg.jpg" alt="User" className="profile-avatar" />
        </div>
        {isMenuOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
            <div className="user-info">
              <div className="user-name">{userDetails.firstname} {userDetails.lastname}</div>
              <div className="user-email">{userDetails.email}</div>
            </div>
            <Link 
              to="/profile" 
              className={`dropdown-item ${currentPath === '/profile' ? 'active' : ''}`}
            >
              <FaUser style={{ fontSize: '1.2rem' }} className='icon' />
              Profile
            </Link>
            <button className="dropdown-item logout" onClick={logout}>
              <FaSignOutAlt style={{ fontSize: '1.2rem', color: '#f72d2d' }} className='icon' />
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;