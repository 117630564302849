import React, { useState, useEffect, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai"; 
import { FiDownload } from "react-icons/fi"; 
import { FaUser } from "react-icons/fa"; 
import { BiCube } from "react-icons/bi"; 
import config from "../config";
import '../Css/FileOptionMenu.css';

const FileOptionsMenu = ({ file, onView3D, onDownload, uploader, onFileDeleted }) => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);

    const handleDelete = async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        try {
            const id = file.fileName;
            const type = file.type === 'model' ? 'model' :
                         file.type === 'video' ? 'video' : 'image';
                        
            const deleteUrl = `${process.env.REACT_APP_API_URL}/delete`;
            
            console.log('Attempting to delete:', deleteUrl);

            const response = await fetch(deleteUrl, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    type: type,
                    id: id,
                 }),
            });

            if (response.ok) {
                if (onFileDeleted) {
                    onFileDeleted();
                }
                alert('File deleted successfully');
            } else {
                const errorData = await response.json();
                alert(errorData.msg || 'Failed to delete file');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Error deleting file');
        }
        setShowMenu(false);
    };

    const handleMenuToggle = () => {
        setShowMenu(prevState => !prevState);
    };

    const handleMenuItemClick = (callback) => {
        setShowMenu(false);
        if (callback) callback();
    };

    const handleViewUploader = () => {
        alert(`Uploader: ${uploader}`);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="file-options-menu" ref={menuRef}>
            <BsThreeDotsVertical className="three-dots-icon" onClick={handleMenuToggle} />
            {showMenu && (
                <div className="menu-dropdown">
                    <div className="menu-item" onClick={() => handleMenuItemClick(handleViewUploader)}>
                        <FaUser className="menu-icon" />
                        <span>View Uploader</span>
                    </div>
                    <div className="menu-item" onClick={() => handleMenuItemClick(onView3D)}>
                        <BiCube className="menu-icon" />
                        <span>View in 3D</span>
                    </div>
                    <div className="menu-item" onClick={() => handleMenuItemClick(onDownload)}>
                        <FiDownload className="menu-icon" />
                        <span>Download</span>
                    </div>
                    <div className="menu-item delete-option" onClick={() => handleMenuItemClick(handleDelete)}>
                        <AiOutlineDelete className="menu-icon" />
                        <span>Delete</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileOptionsMenu;