import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); 
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSendOTP = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter your email');
      setIsSuccess(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('OTP sent to your email');
        setIsSuccess(true);
        setStep(2); 
      } else {
        setMessage(data.error || 'Failed to send OTP');
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!otp || !newPassword) {
      setMessage('Please enter OTP and new password');
      setIsSuccess(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp, newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password reset successfully');
        setIsSuccess(true);
        setTimeout(() => navigate('/'), 1000); 
      } else {
        setMessage(data.error || 'Failed to reset password');
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="component">
      <h1 className="signup-text">Reset Password</h1>
      {message && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>{message}</p>
      )}

      {step === 1 && (
        <form onSubmit={handleSendOTP}>
          <input
            className="inputBox"
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <button
            className="appButton"
            type="submit"
            disabled={loading || !email}
          >
            {loading ? 'Sending OTP...' : 'Send OTP'}
          </button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleResetPassword}>
          <input
            className="inputBox"
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={loading}
            maxLength="6"
          />
          <input
            className="inputBox"
            type="password"
            placeholder="Enter New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            disabled={loading}
          />
          <button
            className="appButton"
            type="submit"
            disabled={loading || !otp || !newPassword}
          >
            {loading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
      )}

      <p className="signup-text">
        Remember password?{' '}
        <span onClick={() => navigate('/')} className="signup-link">
          Login
        </span>
      </p>
    </div>
  );
};

export default ResetPassword;
