import React, { useEffect, useState } from 'react';
import '../Css/Profile.css';
import config from "../config";

const Profile = () => {
    const token = localStorage.getItem("token");
    const userEmail = token ? JSON.parse(atob(token.split('.')[1])).email : '';

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!token) return;

        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/get-user`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.status) {
                    setFirstname(data.user.firstname);
                    setLastname(data.user.lastname);
                } else {
                    setMessage(data.error);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchUserDetails();
    }, [token]);

    const handleSaveChanges = async () => {
        if (!token) return;
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/update-user`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ firstname, lastname })
            });
            
            const data = await response.json();
            
            if (data.status) {
                // Explicitly update state with the server response
                setFirstname(data.user.firstname);
                setLastname(data.user.lastname);
                setMessage('User details updated successfully');
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            console.error('Error updating user details:', error);
            setMessage('Failed to update user details');
        }
    };

    if (!token) {
        return <p>Please log in to view your profile.</p>;
    }

    return (
        <div className="profile-container">
            <h1 className="h1">Profile</h1>
            <p className="h2">Personal info</p>

            <div className="profile-user-name">
                <p className="your-name">FIRST NAME</p>
                <input
                    type="text"
                    className="name-input"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                />
                <p className="your-name">LAST NAME</p>
                <input
                    type="text"
                    className="name-input"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                />
            </div>

            <div className="profile-user-email">
                <p className="email">EMAIL</p>
                <p className="your-email">{userEmail}</p>
            </div>

            <button className="save-button" onClick={handleSaveChanges}>
                Save Changes
            </button>
            {message && <p className="update-message">{message}</p>}
        </div>
    );
};

export default Profile;
