import React from "react";
import '../Css/Notification.css';

const Notification = ({ message, onClose }) => {
    return (
        <div className="notification">
            <span>{message}</span>
            <button className="close-btn" onClick={onClose}>×</button>
        </div>
    );
};

export default Notification;
