import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import config from '../config';

const FILE_TYPES = {
  MODELS: 'Models',
  TEXTURES: 'Textures',
};

const ModelView = () => {
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState('');
  const [fileType, setFileType] = useState(FILE_TYPES.MODELS);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  const fetchFileList = useCallback(async () => {
    setIsLoading(true);
    setError('');
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/listModel/${fileType}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, 
        }
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          throw new Error('Unauthorized. Please login again.');
        } else {
          throw new Error('Failed to fetch file list');
        }
      }

      const data = await response.json();
      if (data && data.files && Array.isArray(data.files)) {
        setFileList(data.files);
      } else {
        throw new Error('Unexpected API response structure');
      }
    } catch (err) {
      setError(err.message);
      if (err.message.includes('Unauthorized')) {
        localStorage.removeItem("token");
        navigate("/");
      }
    } finally {
      setIsLoading(false);
    }
  }, [fileType, navigate]);


  useEffect(() => {
    fetchFileList();
  }, [fetchFileList]);

  const fetchAndDisplayModels = async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/download/${fileType}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch download URL: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const modelUrl = data.url;
      console.log('Signed URL:', modelUrl);

      setSelectedModel(modelUrl);  // This will trigger the useEffect for 3D display

    } catch (err) {
      console.error('Error in fetchAndDisplayModels:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (selectedModel && canvasRef.current) {
      const currentCanvas = canvasRef.current;

      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x0f3d3e);

      const camera = new THREE.PerspectiveCamera(75, canvasRef.current.clientWidth / canvasRef.current.clientHeight, 0.1, 1000);
      camera.position.z = 5;

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
      canvasRef.current.innerHTML = '';
      canvasRef.current.appendChild(renderer.domElement);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);
      
      const directionalLight = new THREE.DirectionalLight(0xffcc66, 1);
      directionalLight.position.set(0, 5, 5);
      scene.add(directionalLight);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;

      const loader = new GLTFLoader();
      loader.load(
        selectedModel,
        (gltf) => {
          scene.add(gltf.scene);

          const box = new THREE.Box3().setFromObject(gltf.scene);
          const center = box.getCenter(new THREE.Vector3());
          gltf.scene.position.sub(center);

          const size = box.getSize(new THREE.Vector3());
          const maxDim = Math.max(size.x, size.y, size.z);
          const fov = camera.fov * (Math.PI / 180);
          let cameraZ = Math.abs(maxDim / 4 / Math.tan(fov / 2));
          camera.position.z = cameraZ;

          camera.near = cameraZ / 100;
          camera.far = cameraZ * 100;
          camera.updateProjectionMatrix();
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          console.error('An error happened', error);
          setError('Failed to load 3D model');
        }
      );

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      const handleResize = () => {
        if (canvasRef.current) {
          camera.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
        }
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        if (currentCanvas) {
          currentCanvas.innerHTML = '';
        }
      };
    }
  }, [selectedModel]);


  const previewTexture = async (fileName) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }

      const response = await fetch(`${config.apiUrl}/download/${fileName}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch texture: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setPreviewImage(data.url); // Set the signed URL as the preview image
    } catch (err) {
      console.error('Error in previewTexture:', err);
      setError(err.message);
    }
  };

    return (
      <div>
        <h1>{fileType} List</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <div>
          <button className='InappButton' onClick={() => setFileType(FILE_TYPES.MODELS)}>Show Models List</button>
          <button className='InappButton' onClick={() => setFileType(FILE_TYPES.TEXTURES)}>Show Textures List</button>
        </div>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {fileList.length > 0 ? (
              fileList.map((id, index) => (
                <li key={index}>
                  {id}
                  {fileType === FILE_TYPES.MODELS && (
                    <button className='InappButton' onClick={() => fetchAndDisplayModels(id)}>View 3D</button>
                  )}
                  {fileType === FILE_TYPES.TEXTURES && (
                    <button className='InappButton' onClick={() => previewTexture(id)}>Preview</button>
                  )}
                </li>
              ))
            ) : (
              <li>No files found</li>
            )}
          </ul>
        )}

        <div className="canvas-wrapper" style={{ display: selectedModel ? 'block' : 'none' }}>
          <div ref={canvasRef} className="canvas-element"></div>
          {selectedModel && <button onClick={() => setSelectedModel(null)}>Close 3D View</button>}
        </div>

        {previewImage && (
          <div className="texture-preview-modal">
            <img src={previewImage} alt="Texture Preview" />
            <button onClick={() => setPreviewImage(null)}>Close Preview</button>
          </div>
        )}
      </div>
    );
  };
    
    export default ModelView;