import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import config from '../config';

const List = () => {
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch the metadata file list from the API
  const fetchMetaList = async () => {
    setIsLoading(true);
    setError('');
    const token = localStorage.getItem("token");
    
    if (!token) {
      navigate("/");
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/metalist`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, 
        }
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          throw new Error('Unauthorized. Please login again.');
        } else {
          throw new Error('Failed to fetch metadata file list');
        }
      }

      const data = await response.json();
      if (data && data.files && Array.isArray(data.files)) {
        setFileList(data.files);
      } else {
        throw new Error('Unexpected API response structure');
      }
    } catch (err) {
      setError(err.message);
      if (err.message.includes('Unauthorized')) {
        localStorage.removeItem("token");
        navigate("/");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch the metadata list on component mount
  useEffect(() => {
    fetchMetaList();
  });

  return (
    <div>
      <h1>Metadata File List</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {fileList.length > 0 ? (
            fileList.map((file, index) => (
              <li key={index}>
                <p><strong>ID:</strong> {file.id}</p>
                <p><strong>Uploader:</strong> {file.uploader}</p>
                <p><strong>Filename:</strong> {file.filename}</p>
                <p><strong>Upload Type:</strong> {file.uploadType}</p>
                <p><strong>Created At:</strong> {new Date(file.createdAt).toLocaleString()}</p>
              </li>
            ))
          ) : (
            <li>No files found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default List;
