import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import config from "../config";
import '../Css/Upload.css';
import { BiSearch } from 'react-icons/bi';
import Notification from './Notification';
import FileOptionsMenu from "./FileOptionMenu";
import { jwtDecode } from 'jwt-decode';

function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Invalid token", error);
      return true;
    }
}
  

const Upload = ({ setIsLoggedIn }) => {
    const [showImportModal, setShowImportModal] = useState(false);
    const [file, setFile ] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [notification, setNotification] = useState(null);
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();


    const fetchSignedUrl = async (fileName, fileType, token) => {
        try {
            const urlResponse = await fetch(`${process.env.REACT_APP_API_URL}/download`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: fileType, id: fileName }),
            });

            if (!urlResponse.ok) {
                throw new Error('Failed to fetch signed URL');
            }

            const urlData = await urlResponse.json();
            return urlData.url;
        } catch (error) {
            console.error(`Error getting URL for ${fileType} ${fileName}:`, error);
            return null;
        }
    };

    const fetchUploads = async () => {
        const token = localStorage.getItem("token");
        if (!token || isTokenExpired(token)) {
            setIsLoggedIn(false);
            navigate("/");
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/model-list`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: '' }),
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    setIsLoggedIn(false);
                    navigate("/");
                    return;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (!data.files) {
                console.error('No files in response:', data);
                setUploadedFiles([]);
                return;
            }
    
            const allFiles = Array.isArray(data.files) ? data.files.map(file => ({
                fileName: file.name,
                url: null,
                type: file.type === 'model' ? 'model' : 
                      file.type === 'video' ? 'video' : 'image',
            })) : [];
    
            const filesWithUrls = await Promise.all(
                allFiles.map(async (file) => {
                    const url = await fetchSignedUrl(file.fileName, file.type, token);
                    return url ? { ...file, url } : null;
                })
            );
    
            const validFiles = filesWithUrls.filter(file => file !== null);
            setUploadedFiles(validFiles);
    
        } catch (error) {
            console.error("Error fetching files:", error);
            setNotification('Error fetching files');
            setUploadedFiles([]);
        }
    };
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token || isTokenExpired(token)) {
            setIsLoggedIn(false);
            navigate("/"); 
        } else {
            fetchUploads();
        }
    }, [setIsLoggedIn, navigate]);
    

   

    const handleUpload = async (selectedFile) => {
        const token = localStorage.getItem("token");
        if (!selectedFile || !token) {
            setNotification("Please select a file and make sure you're logged in!");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        
        const uniqueId = uuidv4();
        formData.append('id', uniqueId);

        const jsonObject = {
            id: uniqueId,
            productName: "BRep_1_82",
            position: "(0,0,0)",
            rotation: "(0,0,0)",
            scale: "(1,1,1)",
            positionConstrained: {
              x: true,
              y: true,
              z: true
            },
            rotationConstrained: {
              x: true,
              y: true,
              z: true
            }
          };

        formData.append('json', JSON.stringify(jsonObject));

        let type;
        if (selectedFile.type.startsWith('image/')) {
            type = 'image';
        } else if (selectedFile.type.startsWith('video/')) {
            type = 'video';
        } else if (selectedFile.name.endsWith('.glb') || selectedFile.name.endsWith('.gltf')) {
            type = 'model';
        } else {
            setNotification('Unsupported file type. Only images or GLB/GLTF files are allowed.');
            return;
        }

        formData.append('type', type);

        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (result.status === 401) {
                setIsLoggedIn(false);
                navigate("/");
                return;
            }

            if (!result.ok) {
                throw new Error('Upload failed');
            }

            setNotification(`Upload successful: ${selectedFile.name}`);
            
            await fetchUploads();
            
            setFile(null);
            setShowImportModal(false);
        } catch (error) {
            console.error('Error during upload:', error);
            setNotification('Upload failed, only images or GLB files are allowed.');
        }
    };

    // const FilePreview = ({ file }) => {
    //     if (!file.url) return null;
    
    //     const handleView3D = () => {
    //         alert('View 3D functionality triggered for ' + file.fileName);
    //     };
    
    //     const handleDownload = () => {
    //         window.open(file.url, '_blank');
    //     };
    
    //     const handleFileDeleted = () => {
    //         fetchUploads();
    //     };
    
    //     const uploader = "John Doe";
    
    //     const renderPreview = () => {
    //         if (file.type === 'model') {
    //             return <div className="model-icon">📦</div>;
    //         } else if (file.type === 'video') {
    //             return (
    //                 <video 
    //                     className="preview-video"
    //                     controls
    //                     preload="metadata"
    //                 >
    //                     <source src={file.url} type="video/mp4" />
    //                     Your browser does not support the video tag.
    //                 </video>
    //             );
    //         } else {
    //             return (
    //                 <img
    //                     src={file.url}
    //                     alt={file.fileName}
    //                     className="preview-image"
    //                     onError={(e) => {
    //                         e.target.onerror = null;
    //                         e.target.src = 'placeholder-image-url.jpg';
    //                     }}
    //                 />
    //             );
    //         }
    //     };
    
    //     return (
    //         <div className="file-preview">
    //             {renderPreview()}
    //             <span className="filename">{file.fileName}</span>
    //             <div className="options-menu">
    //                 <FileOptionsMenu
    //                     file={file}
    //                     onView3D={handleView3D}
    //                     onDownload={handleDownload}
    //                     uploader={uploader}
    //                     onFileDeleted={handleFileDeleted}
    //                 />
    //             </div>
    //         </div>
    //     );
    // };
    
    const FilePreview = ({ file }) => {
        if (!file.url) return null;
    
        const handleView3D = () => {
            alert('View 3D functionality triggered for ' + file.fileName);
        };
    
        const handleDownload = () => {
            window.open(file.url, '_blank');
        };
    
        const handleFileDeleted = () => {
            fetchUploads();
        };
    
        const uploader = "John Doe";
    
        const playVideoInFullScreen = (videoElement) => {
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.webkitRequestFullscreen) {
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.mozRequestFullScreen) {
                videoElement.mozRequestFullScreen();
            } else if (videoElement.msRequestFullscreen) {
                videoElement.msRequestFullscreen();
            }
            videoElement.play();
        };
    
        const renderPreview = () => {
            if (file.type === 'model') {
                return <div className="model-icon">📦</div>;
            } else if (file.type === 'video') {
                return (
                    <div className="video-container">
                        <video
                            className="preview-video"
                            muted
                            autoPlay
                            preload="metadata"
                            onEnded={(e) => e.target.pause()} // Pause after 5 seconds
                            onClick={(e) => playVideoInFullScreen(e.target)} // Fullscreen on click
                        >
                            <source src={file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="video-play-overlay"></div>
                    </div>
                );
            } else {
                return (
                    <img
                        src={file.url}
                        alt={file.fileName}
                        className="preview-image"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'placeholder-image-url.jpg';
                        }}
                    />
                );
            }
        };
    
        return (
            <div className="file-preview">
                {renderPreview()}
                <span className="filename">{file.fileName}</span>
                <div className="options-menu">
                    <FileOptionsMenu
                        file={file}
                        onView3D={handleView3D}
                        onDownload={handleDownload}
                        uploader={uploader}
                        onFileDeleted={handleFileDeleted}
                    />
                </div>
            </div>
        );
    };
       


    return (
        <div className="upload-container">
            <h1 className="h1">My files</h1>
            
            <div className="search">
                <div className="search-container">
                    {/* <BiSearch className="search-icon" />
                    <input type="text" placeholder="Search" className="search-input" /> */}
                </div>
                <button onClick={() => setShowImportModal(true)} className="import-button">
                    Import
                </button>
            </div>

            <div className="file-grid">
                {uploadedFiles.map((file, index) => (
                    <div key={index} className="file-item">
                        <FilePreview file={file} />
                    </div>
                ))}
            </div>

            {showImportModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowImportModal(false)}>&times;</span>
                        <h2>Upload</h2>
                        <div className="drop-area">
                            <p>png, jpg, jpeg,mp4 and glb</p>
                            <button 
                                className="browse-button" 
                                onClick={() => hiddenFileInput.current.click()}
                            >
                                Browse
                            </button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    if (selectedFile) {
                                        setFile(selectedFile);
                                        handleUpload(selectedFile);
                                    }
                                }}
                                style={{ display: "none" }}
                                accept=".png,.jpg,.jpeg,.glb,.mp4,.mov,.avi"
                            />
                        </div>
                        <p className="max-size">Max size 50 MB</p>
                    </div>
                </div>
            )}

            {notification && (
                <Notification 
                    message={notification} 
                    onClose={() => setNotification(null)} 
                />
            )}
        </div>
    );
};

export default Upload;