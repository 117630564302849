import React, { useState } from 'react';

const Build = () => {
  const [loading, setLoading] = useState(true);
  

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      overflow: 'hidden'
    }}>
      {loading && <div>Loading...</div>}
      <iframe
        src="https://s3.ap-south-1.amazonaws.com/app.visionxr.in/Visionxr/index.html"
        style={{
          width: '500px',  // Set the desired width
          height: '250px', // Set the desired height
          border: 'none'
        }}
        title="Unity Build"
        onLoad={handleLoad}
      ></iframe>
    </div>
  );
};

export default Build;

