import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../config";

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const email = localStorage.getItem('verificationEmail');

  useEffect(() => {
    if (!email) {
      navigate('/signup');
    }
  }, [email, navigate]);

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && value.length <= 6)) {
      setOtp(value);
    }
  };

  const handleBackToSignup = () => {
    localStorage.removeItem('verificationEmail');
    navigate('/signup', { replace: true });
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    
    if (otp.length !== 6) {
      setMessage('Please enter complete 6-digit OTP');
      setIsSuccess(false);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email,
          otp 
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('User created successfully!');
        setIsSuccess(true);
        localStorage.removeItem('verificationEmail');
        navigate('/', { replace: true });
      } else {
        setMessage(data.error || 'OTP verification failed');
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (loading) return;
    
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/resend-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('New OTP sent successfully');
        setIsSuccess(true);
      } else {
        if (data.error === "No signup data found. Please signup again.") {
          handleBackToSignup();
        } else {
          setMessage(data.error || 'Failed to resend OTP');
          setIsSuccess(false);
        }
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="component">
      <h1 className='signup-text'>OTP Verification</h1>
      {message && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>{message}</p>
      )}
      <p style={{ fontSize: '14px', color: '#666' }}>
        Wrong email? {' '}
        <span 
          className="signup-link" 
          onClick={handleBackToSignup}
          style={{ cursor: 'pointer' }}
        >
          Go back to signup
        </span>
      </p>
      <form onSubmit={handleVerifyOtp}>
        <input
          type="text"
          placeholder="Enter 6-digit OTP"
          value={otp}
          onChange={handleOtpChange}
          className="inputBox"
          disabled={loading}
          pattern="\d{6}"
          maxLength="6"
          minLength="6"
          inputMode="numeric"
          autoComplete="one-time-code"
          style={{ 
            letterSpacing: '8px',
            fontWeight: '600',
            textAlign: 'center'
          }}
        />
        <button 
          type="submit" 
          className="appButton"
          disabled={loading || otp.length !== 6}
        >
          {loading ? 'Verifying...' : 'Verify OTP'}
        </button>
      </form>
      <p className="signup-text">
        Didn't receive OTP? 
        <span 
          className="signup-link" 
          onClick={handleResendOTP}
          style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
        >
          {' '}Resend OTP
        </span>
      </p>
    </div>
  );
};

export default OtpVerification;