import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../config";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstname, lastname, email, password } = formData;

    // Quick validation
    if (!firstname || !lastname || !email || !password) {
      setMessage('All fields are required.');
      setIsSuccess(false);
      return;
    }

    if (!isEmailValid(email)) {
      setMessage('Please enter a valid email address.');
      setIsSuccess(false);
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout

      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (response.ok) {
        localStorage.setItem('verificationEmail', email);
        navigate('/otp-verification', { replace: true });
      } else {
        const data = await response.json();
        setMessage(data.error || 'Signup failed');
        setIsSuccess(false);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        setMessage('Request timed out. Please try again.');
      } else {
        setMessage(`Error: ${error.message}`);
      }
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="component">
      <h1 className="signup-text">Signup</h1>
      {message && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>{message}</p>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <input
          className="inputBox"
          type="text"
          name="firstname"
          placeholder="Enter First Name"
          value={formData.firstname}
          onChange={handleInputChange}
          disabled={loading}
        />
        <input
          className="inputBox"
          type="text"
          name="lastname"
          placeholder="Enter Last Name"
          value={formData.lastname}
          onChange={handleInputChange}
          disabled={loading}
        />
        <input
          className="inputBox"
          type="email"
          name="email"
          placeholder="Enter Email"
          value={formData.email}
          onChange={handleInputChange}
          disabled={loading}
        />
        <input
          className="inputBox"
          type="password"
          name="password"
          placeholder="Enter Password"
          value={formData.password}
          onChange={handleInputChange}
          disabled={loading}
        />
        <button
          className="appButton"
          type="submit"
          disabled={loading || !formData.firstname || !formData.lastname || !formData.email || !formData.password}
        >
          {loading ? 'Please wait...' : 'Verify Email'}
        </button>
      </form>
      <p className="signup-text">
        Already have an account? 
        <span 
          className="signup-link" 
          onClick={() => !loading && navigate('/')}
          style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
        > 
          Login
        </span>
      </p>
    </div>
  );
};

export default Signup;