import React from 'react';
import '../Css/Home.css'; 

const Home = () => {
  return (
    <div className="homepage">
      <div className="homepage-content">
        <h1>Welcome to VisionXR</h1>
        <p>This is your homepage. Upload your models, textures and manage projects.</p>
      </div>
    </div>
  );
};

export default Home;

